<template>
	<div @click.stop="clickHandel">
		<slot></slot>
		<div class="addRole flex flex-col" v-if="Visible">
			<div class="flex flex-ac pt20 pb20 pl15 box">
				<div class="pad15" @click.stop="cancleHandel">
					<img class="db" src="../images/back.png" />
				</div>
				<span class="ft20 c333 bold">新增角色</span>
			</div>
			<div class="it1 pl30 pr30 pb30 box overflow">
				<div class="h100 flex">
					<div class="permission pad30 box">
						<span class="ft18 c333">选择权限</span>
						<div class="pl20 pt20 pb20 box">
							<el-tree 
								ref="moduleTree"
								:data="data" 
								show-checkbox 
								node-key="id" 
								props="defaultProps"
							>
							</el-tree>
						</div>
					</div>
					<div class="ml35">
						<div class="flex">
							<span class="leftText ft14 c666 mr5">角色名称：</span>
							<el-input class="it1" v-model="roleName" placeholder="请输入角色名称"></el-input>
						</div>
						<div class="flex mt15">
							<span class="leftText ft14 c666 mr5">职责说明：</span>
							<el-input class="it1" type="textarea" :rows="5" maxlength="50" show-word-limit placeholder="请输入职责说明" v-model="jobDescription">
							</el-input>
						</div>
						<div class="flex flex-pe mt35">
							<el-button type="primary"  @click.stop="confirmHandel">确定</el-button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { getFunExist } from '@/utils/commonFun';
	import axios from '@/utils/axios';
	import './AddWin.less';
	export default {
		name: 'AddWin',
		props: {
			getData: {
				type: Function,
				default: () => {}
			}
		},
		data() {
			return {
				Visible: false,
				roleName: '',
				jobDescription: '',
				data: [
					{
						id: 'work_bench',
						label: '工作台',
						disabled: !getFunExist('work_bench')
					}, 
					{
						id: 'big_screen',
						label: '大屏',
						disabled: !getFunExist('big_screen')
					},
					{
						id: 'operation_monitoring',
						label: '运维监控',
						disabled: !getFunExist('operation_monitoring')
					},
					{
						id: 'workorder_management',
						label: '工单管理',
						disabled: !getFunExist('workorder_management'),
						children: [{
							id: 'trouble_shooting',
							label: '故障救援',
							disabled: !getFunExist('trouble_shooting')
						}, {
							id: 'planned_maintenance',
							label: '计划检修',
							disabled: !getFunExist('planned_maintenance')
						},{
							id: 'back_job',
							label: '回调工单',
							disabled: !getFunExist('back_job')
						},{
							id: 'sale_job',
							label: '售后工单',
							disabled: !getFunExist('sale_job')
						},{
							id: 'mine_job',
							label: '我的工单',
							disabled: !getFunExist('mine_job')
						}]
					},
					{
						id: 'customer_center',
						label: '客服中心',
						disabled: !getFunExist('customer_center'),
						children: [{
							id: 'customer_record',
							label: '客服记录',
							disabled: !getFunExist('customer_record')
						}, {
							id: 'knowledge_base',
							label: '知识库管理',
							disabled: !getFunExist('knowledge_base')
						}]
					},
					{
						id: 'state_management',
						label: '状态管理',
						disabled: !getFunExist('state_management'),
						children: [{
							id: 'status_management',
							label: '状态管理',
							disabled: !getFunExist('status_management')
						}, {
							id: 'device_control',
							label: '设备控制',
							disabled: !getFunExist('device_control')
						}]
					},
					{
						id: 'asset_management',
						label: '运维管理',
						disabled: !getFunExist('asset_management'),
						children: [
							{
								id: 'site_management',
								label: '站点管理',
								disabled: !getFunExist('site_management'),
							}, {
								id: 'battery_management',
								label: '电池管理',
								disabled: !getFunExist('battery_management'),
							},
							{
								id: 'operation_team_management',
								label: '运维班组管理',
								disabled: !getFunExist('operation_team_management'),
							}, {
								id: 'operation_personnel_management',
								label: '运维人员管理',
								disabled: !getFunExist('operation_personnel_management'),
							},
							{
								id: 'device_management',
								label: '设备管理',
								disabled: !getFunExist('device_management'),
							}, {
								id: 'supplier_management',
								label: '供应商管理',
								disabled: !getFunExist('supplier_management'),
							},
						]
					},
					{
						id: 'message_center',
						label: '信息中心',
						disabled: !getFunExist('message_center'),
						children: [{
							id: 'message_list',
							label: '告警消息',
							disabled: !getFunExist('message_list'),
						}, {
							id: 'message_management',
							label: '公告消息',
							disabled: !getFunExist('message_management'),
						},{
							id: 'un_online_msg',
							label: '离线消息',
							disabled: !getFunExist('un_online_msg'),
						},{
							id: 'cloud_control',
							label: '云控信息',
							disabled: !getFunExist('cloud_control'),
						},{
							id: 'customer_information',
							label: '客服信息',
							disabled: !getFunExist('customer_information'),
						}]
					},
					{
						id: 'promotion_management',
						label: '升级管理',
						disabled: !getFunExist('promotion_management'),
						children: [{
							id: 'upgrade_management',
							label: '升级管理',
							disabled: !getFunExist('upgrade_management'),
						}, {
							id: 'upgrade_log',
							label: '升级日志',
							disabled: !getFunExist('upgrade_log'),
						},{
							id: 'version_management',
							label: '版本管理',
							disabled: !getFunExist('version_management'),
						}]
					}, 
					{
						id: 'system_settings',
						label: '系统设置',
						disabled: !getFunExist('system_settings'),
						children: [
							{
								id: 'basic_settings',
								label: '消息设置',
								disabled: !getFunExist('basic_settings'),
							},
							{
								id: 'organization_management',
								label: '组织管理',
								disabled: !getFunExist('organization_management'),
							}, {
								id: 'user_management',
								label: '用户管理',
								disabled: !getFunExist('user_management'),
							},
							{
								id: 'role_management',
								label: '角色管理',
								disabled: !getFunExist('role_management'),
							}, {
								id: 'login_log_management',
								label: '登录日志管理',
								disabled: !getFunExist('login_log_management'),
							},
						]
					}, 
				],
				defaultProps: {
					children: 'children',
					label: 'label'
				},
				organId: null,
			}
		},
		computed: {
		},
		mounted() {
			this.organId = localStorage.getItem('organId')
		},
		methods: {
			clickHandel() {
				this.Visible = true;
			},
			cancleHandel(){
				this.Visible = false;
			},
			funExist(id) {
				return getFunExist(id)
			},
			async confirmHandel(){
				const {
					roleName,
					jobDescription
				} = this;
				const funs = this.$refs.moduleTree.getCheckedKeys();
				const funsHalf = this.$refs.moduleTree.getHalfCheckedKeys();
				if (!roleName || !jobDescription || [...funs, ...funsHalf].length === 0) {
					this.$message.info('请完善角色相关信息');
					return;
				}
				if(roleName === '供应商') {
					return this.$message.error('系统默认角色，不能添加');
				}
				const rs = await axios.post('/api/role/create', { roleName, jobDescription, del: 0, status: 1, organId: this.organId });
				const roleId = rs.id;
				const list = [...funs, ...funsHalf].map(item => ({ roleId, permissionsCode: item }));
				await axios.post('/api/rolePermissions/updateRolePermissions',{ list });
				this.$message.success('添加成功');
				this.Visible = false;
				this.roleName = '';
				this.jobDescription = '';
				this.$refs.moduleTree.setCheckedKeys([]);
				this.getData();
			}
		}
	}
</script>
